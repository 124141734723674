import usePortal from '../../Hooks/usePortal'

const Portal = ({ selector, children }) => {
  const PortalMagic = usePortal(document.querySelector(selector))

  return (
    <PortalMagic>
      {children}
    </PortalMagic>
  )
}

export default Portal
