import { Suspense, useState } from 'react'
import { Await, Link, useLoaderData } from 'react-router-dom'
import PaidStatus from '../../../Components/Dashboard/PaidStatus'
import useTable from '../../../Components/Dashboard/Table/useTable'
import MoneyFormat from '../../../Components/Utils/MoneyFormat'
import InvoiceEmpty from '../../../Assets/images/InvoiceEmpty.png'
import Support from './Support'
import { BsX } from 'react-icons/bs'
import Panel from '../../../Components/Dashboard/Panel'

const SupportIndex = () => {
  const items = useLoaderData()

  const { Table, RowHeader, ColumnHeader, Column, Row, Body } = useTable()

  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>
            Category
          </ColumnHeader>
          <ColumnHeader>
            Sub Category
          </ColumnHeader>
          <ColumnHeader>
            Incidents
          </ColumnHeader>
          <ColumnHeader>
            Timestamp
          </ColumnHeader>
          <ColumnHeader>
            Status
          </ColumnHeader>
        </RowHeader>
        <Body data={items}>
          {data => (data?.data?.data?.map((v, i) =>
            (<Row key={i}>
              <Column>
                {v.category}
              </Column>
              <Column>
                {v.sub_category}
              </Column>
              <Column>
                <p className='text-ellipsis overflow-hidden whitespace-nowrap w-24'>{v.message}</p>
              </Column>
              <Column>
                {new Date(v.created_at).toDateString()}
              </Column>
              <Column>
                <div className='text-right '><PaidStatus value={v.status} /></div>
              </Column>

             </Row>)))}
        </Body>
      </Table>
      <SupportEmptyState items={items} />
    </Panel>
  )
}

const SupportEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div />}>
      <Await
        resolve={items?.data}
      >
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div
                className='bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center'
              >
                <img src={InvoiceEmpty} alt='Transactions' />
                <p className='mt-5 text-[#64748B] mb-5'>You don’t have any incident <br /> at the time</p>
                <CreateRequestButton />
              </div>
            )
          }
        }}
      </Await>
    </Suspense>
  )
}

const CreateRequestButton = () => {
  const [state, setState] = useState(false)
  return (
    <>
      <button onClick={() => setState(v => !v)} className='bg-black text-white px-6 py-3 rounded-[8px] flex items-center justify-center'>
        <span>
          + Log Support Request
        </span>
      </button>
      <div data-active={state} className=' data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70'>
        <div className='relative max-w-md md:max-w-4xl w-full bg-white py-8 rounded-[16px]'>
          <button onClick={() => setState(v => !v)} className='absolute -left-3 -top-3 rounded-full bg-white'>
            <BsX className='w-6 h-6' />
          </button>
          <Support setState={setState} />
        </div>
      </div>
    </>
  )
}

export default SupportIndex
