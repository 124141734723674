import BaseController from './BaseController'
import http from '../../Store/baseHttp'
import ResponseObject from '../../DataObjects/ResponseObject'
// import UserObject from "../../DataObjects/UserObject";
// import FormData from "form-data";
import UserObject from '../../DataObjects/UserObject'
// import * as fs from 'fs';

class DashboardController extends BaseController {
  async index ({ request, params }) {
    const url = new URL(request.url)
    try {
      let response = await http.get('/dashboard/stats' + url.search)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async post ({ request }) {
    try {
      const formData = Object.fromEntries(await request.formData())

      const myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + UserObject.getUser().token)

      const transData = new FormData()
      Object.keys(formData).map(v => transData.append(v, formData[v]))
      // transData.append('invoice', formData.invoice);
      // transData.append('invoice_number', formData.invoice_number);
      // transData.append('company', formData.company);
      // transData.append('due_date', formData.due_date);
      // transData.append('amount', formData.amount);
      // transData.append('service_category', formData.service_category);
      // transData.append('services_rendered', formData.services_rendered);
      // transData.append('mandate_letter', formData.mandate_letter);
      // transData.append('bank_statement', formData.bank_statement);
      // console.log(formData.recent_invoices);
      // transData.append('rencent_invoices', formData.recent_invoices);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: transData,
        redirect: 'follow'
      }

      const response = await (await fetch('https://api.fedhagap.com/api/request/create', requestOptions)).json()

      return ResponseObject.from({ data: response })
      // return response;
    } catch (e) {
      return e?.response
    }
  }

  static async submit (formData) {
    try {
      let response = await http.post('/request/create',
        Object.fromEntries(await formData),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }
}

export default DashboardController
