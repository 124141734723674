import ResponseObject from '../../DataObjects/ResponseObject'
import UserObject from '../../DataObjects/UserObject'
import BaseController from './BaseController'

class KYCController extends BaseController {
  async post ({ request }) {
    try {
      const formData = Object.fromEntries(await request.formData())

      const myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + UserObject.getUser().token)

      const transData = new FormData()
      Object.keys(formData).map(v => transData.append(v, formData[v]))
      // transData.append('invoice', formData.invoice);
      // transData.append('invoice_number', formData.invoice_number);
      // transData.append('company', formData.company);
      // transData.append('due_date', formData.due_date);
      // transData.append('amount', formData.amount);
      // transData.append('service_category', formData.service_category);
      // transData.append('services_rendered', formData.services_rendered);
      // transData.append('mandate_letter', formData.mandate_letter);
      // transData.append('bank_statement', formData.bank_statement);
      // console.log(formData.recent_invoices);
      // transData.append('rencent_invoices', formData.recent_invoices);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: transData,
        redirect: 'follow'
      }

      const response = await (await fetch('https://api.fedhagap.com/api/kyc', requestOptions)).json()

      return ResponseObject.from({ data: response })
      // return response;
    } catch (e) {
      return e?.response
    }
  }
}

export default KYCController
