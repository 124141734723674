import { BsChevronLeft, BsChevronRight, BsEye } from 'react-icons/bs'
import Panel from '../../../Components/Dashboard/Panel'
import { Await, Link, useLoaderData, useParams } from 'react-router-dom'
import { Suspense } from 'react'
import PaidStatus from '../../../Components/Dashboard/PaidStatus'
import MoneyFormat from '../../../Components/Utils/MoneyFormat'

const TransactionView = () => {
  const data = useLoaderData()
  const { id } = useParams()

  return (
    <div>
      <div className='inline-flex w-full items-center justify-between'>
        <Link
          className='rounded-full bg-white p-3'
          to='/dashboard/transactions'
        >
          <BsChevronLeft className='h-4 w-4' />
        </Link>

        <Link
          className='rounded-full bg-white p-3'
          to={`/dashboard/transactions/${id}/approval`}
        >
          <BsChevronRight className='h-4 w-4' />
        </Link>
      </div>

      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={data}>
          {(data) => {
            const item = data?.data?.data
            return (
              <div className='mt-5'>
                <div className='mb-10 flex items-center justify-start space-x-5'>
                  <div className='inline-flex items-center justify-start rounded-xl bg-white p-3 shadow-sm'>
                    <div className='mr-4 h-[48px] w-[48px] rounded-full border border-purple-900' />
                    <div className='flex flex-col items-start justify-center'>
                      <p className='font-semibold'>
                        {item?.creator?.first_name} {item?.creator?.last_name}
                      </p>
                      <p className='text-sm font-light text-gray-500'>
                        {item?.company?.company_name}
                      </p>
                    </div>
                  </div>

                  <div className='inline-flex items-center justify-start rounded-xl bg-white p-3 shadow-sm'>
                    <div className='flex flex-col items-start justify-center'>
                      <p className='font-semibold'>Invoice Wallet Balance</p>
                      <p className='text-sm font-light text-gray-500'>
                        20,0000
                      </p>
                    </div>
                  </div>
                </div>
                <Panel>
                  <div className='grid grid-cols-1 gap-5 px-2 py-5 md:grid-cols-5'>
                    <div className='col-span-1'>
                      <h1 className='text-[16px] font-[500] leading-[24px]'>
                        Invoice Number
                      </h1>
                      <p className='text-[20px] font-[700] leading-[30px]'>
                        {item?.invoice_number}
                      </p>
                    </div>
                    <div className='col-span-1'>
                      <h1 className='text-[16px] font-[500] leading-[24px]'>
                        Created Date
                      </h1>
                      <p className='text-[18px] font-[700] leading-[30px]'>
                        {new Date(item?.created_at).toDateString()}
                      </p>
                    </div>
                    <div className='col-span-1'>
                      <h1 className='text-[16px] font-[500] leading-[24px]'>
                        Due Date
                      </h1>
                      <p className='text-[18px] font-[700] leading-[30px]'>
                        {new Date(item?.due_date).toDateString() ??
                          new Date().toString()}
                      </p>
                    </div>
                    <div className='col-span-1'>
                      <h1 className='text-[16px] font-[500] leading-[24px]'>
                        Fund Status
                      </h1>
                      <p className='text-[18px] font-[700] leading-[30px]'>
                        <PaidStatus value={item?.fund_status ?? 0} />
                      </p>
                    </div>
                    <div className='col-span-1'>
                      <h1 className='text-[16px] font-[500] leading-[24px]'>
                        Amount
                      </h1>
                      <p className='text-[18px] font-[700] leading-[30px]'>
                        ₦{MoneyFormat(item?.amount)}
                      </p>
                    </div>
                  </div>
                </Panel>
                <div className='mt-10'>
                  <Panel>
                    <div className='grid grid-cols-1 gap-10 sm:grid-cols-3'>
                      <div className='col-span-1'>
                        <h1 className='text-[16px] font-[500] leading-[24px]'>
                          Company
                        </h1>
                        <p className='text-[18px] font-[700] leading-[30px]'>
                          {item?.company?.company_name}
                        </p>
                      </div>
                      <div className='col-span-1'>
                        <h1 className='text-[16px] font-[500] leading-[24px]'>
                          Service Category
                        </h1>
                        <p className='text-[18px] font-[700] leading-[30px]'>
                          {item?.service_category}
                        </p>
                      </div>
                      <div className='col-span-1'>
                        <div className='flex w-full items-center  justify-center rounded-[16px] bg-purple-900/70 px-10 py-8'>
                          <Link
                            to={item?.invoice_url}
                            autoSave
                            className='flex items-center justify-between space-x-2 rounded-lg bg-white px-8 py-2 font-semibold text-purple-900'
                          >
                            <BsEye className='h-4 w-4' />
                            <span>View</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </div>
                {parseInt(item?.kyc_status) === 1 && (
                  <div className='mt-[66px] flex items-center justify-end space-x-[10px]'>
                    <button className='rounded-[8px] border-[1.5px] border-[#D0D5DD] bg-[#590DA4] px-[36px] py-[16px] text-[14px] font-[700] leading-[21px] text-white'>
                      Proceed to Payment
                    </button>
                  </div>
                )}
              </div>
            )
          }}
        </Await>
      </Suspense>
    </div>
  )
}

export default TransactionView
