/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Await, useLocation, useSearchParams } from 'react-router-dom'
import { Suspense, useEffect } from 'react'

export const Pagination = ({ children }) => {
  return <div className='flex items-center justify-start'>{children}</div>
}

export const PageSize = ({ numbers = [5, 10, 20, 50] }) => {
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchParams, setSearchParams] = useSearchParams({ page_size: 5 })

  useEffect(() => {
    setSearchParams({ ...Object.fromEntries(search), page_size: 5 })
  }, [])
  return (
    <div className='flex items-center space-x-2 text-sm'>
      <span>Show</span>
      <select
        className='rounded-[8px]'
        onChange={(e) => {
          setSearchParams({ page_size: e.target.value })
        }}
      >
        {numbers.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
      <span>Entries</span>
    </div>
  )
}

export const Button = ({ isActive = false, children, ...props }) => {
  return (
    <button
      className={`flex items-center justify-center rounded-[6px] py-1 px-3 ${
        isActive ? 'bg-[#590da4] text-white' : 'text-black'
      }`}
      {...props}
    >
      <span>{children}</span>
    </button>
  )
}

export const Prev = ({ children, ...props }) => (
  <button
    className='flex h-[29.37px]  w-[29.37px] items-center justify-center rounded-[8px] text-[#590da4] hover:bg-gray-200 hover:text-white'
    {...props}
  >
    <BsChevronLeft className='h-4 w-4' />
  </button>
)

export const Next = ({ children, ...props }) => (
  <button
    className='flex h-[29.37px] w-[29.37px] items-center justify-center rounded-[8px] text-[#590da4] hover:bg-gray-200 hover:text-white'
    {...props}
  >
    <BsChevronRight className='h-4 w-4' />
  </button>
)

export const Description = ({ data }) => {
  return (
    <Suspense fallback={<p>Loading</p>}>
      <Await resolve={data?.data} errorElement={<span>Loading</span>}>
        {(data) => {
          if (!data) {
            return null
          }

          return (
            <span>
              Showing {data?.data?.from} to {data?.data?.to} of{' '}
              {data?.data?.total} entries
            </span>
          )
        }}
      </Await>
    </Suspense>
  )
}

const usePagination = () => {
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchParams, setSearchParams] = useSearchParams()

  const setPage = (value) => {
    setSearchParams({ ...Object.fromEntries(search), page_num: value })
  }

  return {
    Pagination,
    Button,
    Prev,
    Next,
    setPage,
    searchParams,
    PageSize,
    Description
  }
}

export default usePagination
