import useToggler from '../../Hooks/useToggler'
import { Link, useNavigate } from 'react-router-dom'
import UserObject from '../../DataObjects/UserObject'
import useClickOutside from '../../Hooks/useClickOutside'
import { useRef } from 'react'

const Profile = () => {
  const [value, click, , setFalse] = useToggler(false)
  const { first_name, last_name, email, profile_photo } = UserObject.getUser()
  const navigate = useNavigate()
  const profile = useRef()

  useClickOutside(profile, () => {
    setFalse()
  })

  return (
    <div
      ref={profile}
      className='relative flex cursor-pointer items-center'
      onClick={click}
    >
      <div className='mr-3 h-[40px] w-[40px] flex-none overflow-hidden rounded-full'>
        <img src={profile_photo} alt='alp' className='' />
      </div>
      <div>
        <h2 style={styles.profileName}>
          {first_name} {last_name}
        </h2>
        <p style={styles.profileEmail}>{email}</p>
      </div>
      <div className='ml-3 cursor-pointer'>
        <button className='focus:outline-none'>
          <svg
            width='12'
            height='7'
            viewBox='0 0 12 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.2279 1.90437C11.6185 1.51385 11.6185 0.880683 11.2279 0.490159C10.8374 0.0996345 10.2043 0.0996345 9.81373 0.490159L11.2279 1.90437ZM5.85417 5.86393L5.14706 6.57104C5.3346 6.75858 5.58895 6.86393 5.85417 6.86393C6.11938 6.86393 6.37374 6.75858 6.56127 6.57104L5.85417 5.86393ZM1.89461 0.490159C1.50408 0.0996345 0.870918 0.0996345 0.480393 0.490159C0.0898689 0.880683 0.0898689 1.51385 0.480393 1.90437L1.89461 0.490159ZM9.81373 0.490159L5.14706 5.15683L6.56127 6.57104L11.2279 1.90437L9.81373 0.490159ZM6.56127 5.15683L1.89461 0.490159L0.480393 1.90437L5.14706 6.57104L6.56127 5.15683Z'
              fill='#14181F'
            />
          </svg>
        </button>
      </div>
      <div
        className={`absolute top-0 right-0 z-[999] mt-12 flex w-full flex-col items-start space-y-[12px] rounded-[8px] border bg-white py-[16px] px-[20px] shadow-[0px_16px_48px_rgba(0,0,0,0.1)] ${
          value ? '' : 'hidden'
        }`}
      >
        <Link
          to='/dashboard/settings'
          className=' w-full text-[14px] font-[500] leading-[21px] text-[#4b5768]'
        >
          View Profile
        </Link>
        <button
          onClick={() => {
            UserObject.logOutUser()
            navigate('/admin/login')
          }}
          className='w-full text-left text-[14px] font-[500] leading-[21px] text-[#4b5768] hover:text-purple-700 focus:outline-none'
        >
          Logout
        </button>
      </div>
    </div>
  )
}

const styles = {
  profileName: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px'
  },
  profileEmail: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#64748B'
  }
}

export default Profile
