import axios from "axios";
import UserObject from "../DataObjects/UserObject";

// const BASE_URL = "https://api.upload.io"
const BASE_URL = "https://api.fedhagap.com/api";
const http = axios.create({
  baseURL: BASE_URL,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const reqStart = new CustomEvent("start-request", {
      detail: {
        start: true,
      },
    });

    window.dispatchEvent(reqStart);
    const user = UserObject.getUser();
    if (user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const status = error.response.status;
    if (
      Object.keys(ERROR_CODE)
        .map((v) => parseInt(v))
        .includes(status)
    ) {
      // window.location.replace("errors/" + ERROR_CODE[status]);
    }
    return Promise.reject(error);
  }
);

const ERROR_CODE = {
  419: "_419",
  404: "_404",
};

export default http;
