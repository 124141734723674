import useTitle from '../../../Hooks/useTitle'
import useTable from '../../../Components/Dashboard/Table/useTable'
import {
  useFetcher,
  useLoaderData,
  useLocation,
  useSearchParams
} from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import Invoice from '../../../Assets/icons/invoice.png'
import UserObject from '../../../DataObjects/UserObject'
import Logo from '../../../Assets/logo/Logo.png'
import { MdOutlineDriveFolderUpload } from 'react-icons/md'
import { BsX } from 'react-icons/bs'
import Input from '../../../Components/Forms/Input'
import Submit from '../../../Components/Forms/Submit'
import ToastContext from '../../../Routes/ToastContext'
import useForm from '../../../Hooks/useForm'
import Categories from './Categories'
import Countries from './Countries'
import States from './States'
import Banks from './Banks'

const DashboardIndex = () => {
  useTitle('Dashboard')

  const [, setSearchParams] = useSearchParams({ dateFrom: '', dateTo: '' })
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const handleDateChange = (dateFrom, dateTo) => {
    const s = Object.fromEntries(search)
    setSearchParams({ ...s, dateFrom, dateTo })
  }

  return <div className='h-full w-full pb-32' />
}

const EmptyState = ({ utilityBill, cacForm, directorValidId, cac }) => {
  const [state, setState] = useState(true)
  const { organization } = UserObject.getUser()

  return (
    <div className='relative bottom-0 top-0 flex h-full w-full items-center justify-center rounded-[16px] bg-white p-6 '>
      <div className='flex w-full max-w-sm flex-col items-center justify-center'>
        {organization?.kyc_verification_status === '0' && (
          <img
            src={Invoice}
            alt='invoice'
            className='mb-[28.36px] aspect-auto '
          />
        )}
        {organization?.kyc_verification_status === '2' && (
          <svg
            width='78'
            height='78'
            viewBox='0 0 78 78'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M65.8125 29.25V56.0625H26.8125V7.3125H43.875V20.7188C43.875 25.428 47.697 29.25 52.4062 29.25H65.8125ZM51.1875 7.90238L65.2226 21.9375H52.4062C52.083 21.9375 51.773 21.8091 51.5445 21.5805C51.3159 21.352 51.1875 21.042 51.1875 20.7188V7.90238ZM24.375 0C23.0821 0 21.8421 0.513615 20.9279 1.42785C20.0136 2.34209 19.5 3.58207 19.5 4.875V14.625H10.9688C7.605 14.625 4.875 17.355 4.875 20.7188V71.9062C4.875 75.27 7.605 78 10.9688 78H51.1875C54.5513 78 57.2812 75.27 57.2812 71.9062V63.765C57.2816 63.6347 57.2751 63.5046 57.2618 63.375H68.25C69.5429 63.375 70.7829 62.8614 71.6971 61.9471C72.6114 61.0329 73.125 59.7929 73.125 58.5V21.5182C73.1247 20.2254 72.6109 18.9857 71.6966 18.0716L55.0534 1.42838C54.1394 0.514072 52.8996 0.000276107 51.6068 0H24.375ZM19.5 58.5V21.9375H12.1875V70.6875H49.9688V63.765C49.9688 63.6334 49.9736 63.5018 49.9882 63.375H24.375C23.0821 63.375 21.8421 62.8614 20.9279 61.9471C20.0136 61.0329 19.5 59.7929 19.5 58.5Z'
              fill='black'
            />
          </svg>
        )}
        {organization?.kyc_verification_status === '3' && (
          <svg
            width='78'
            height='78'
            viewBox='0 0 78 78'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M39 6.5C56.9725 6.5 71.5 21.0275 71.5 39C71.5 56.9725 56.9725 71.5 39 71.5C21.0275 71.5 6.5 56.9725 6.5 39C6.5 21.0275 21.0275 6.5 39 6.5ZM50.6675 22.75L39 34.4175L27.3325 22.75L22.75 27.3325L34.4175 39L22.75 50.6675L27.3325 55.25L39 43.5825L50.6675 55.25L55.25 50.6675L43.5825 39L55.25 27.3325L50.6675 22.75Z'
              fill='#FD0D1B'
            />
          </svg>
        )}

        <h3 className='mt-[25px] text-[16px] font-[700] leading-[24px] text-[#590DA4] '>
          Dear {UserObject.getUser()?.first_name}{' '}
          {UserObject.getUser()?.last_name}...
        </h3>
        {organization.kyc_verification_status === '0' && (
          <p className='mb-[29.09px] text-center text-[14px] font-[700] leading-[21px]'>
            You are a few steps away from logging your first request, please
            click here to complete your Corporate KYC registration.
          </p>
        )}
        {organization.kyc_verification_status === '2' && (
          <p className='mb-[15.09px] text-center text-[14px] font-[700] leading-[21px]'>
            Your corporate KYC registration status is:
          </p>
        )}
        {organization?.kyc_verification_status === '0' && (
          <button
            onClick={() => setState((v) => !v)}
            className='rounded-[8px] bg-[#0D0F11] py-[9px] px-[16px] text-white'
          >
            + Start Your KYC
          </button>
        )}
        {organization?.kyc_verification_status === '2' && (
          <span className='rounded-full bg-yellow-200 px-3 py-1 text-sm text-yellow-700'>
            Pending
          </span>
        )}
        {organization?.kyc_verification_status === '3' && (
          <>
            <span className='rounded-full bg-red-200 px-3 py-1 text-sm text-red-700'>
              Rejected
            </span>
            <button
              onClick={() => setState((v) => !v)}
              className='rounded-[8px] bg-[#0D0F11] py-[9px] px-[16px] text-white'
            >
              Re-Verify KYC
            </button>
          </>
        )}
        <KYC
          utilityBill={utilityBill}
          isHidden={state}
          handleState={setState}
          directorValidId={directorValidId}
          cacForm={cacForm}
          cac={cac}
        />
      </div>
    </div>
  )
}

const KYC = ({
  isHidden,
  handleState,
  utilityBill,
  directorValidId,
  cacForm,
  cac
}) => {
  const [state, setState] = useState('bus')
  const { flashToast } = useContext(ToastContext)
  const { email, phone_number, organization } = UserObject.getUser()

  const { state: loading, data, submit } = useFetcher()

  const initialState = {
    business_name: organization?.name,
    registration_number: '',
    category: '',
    email,
    address: '',
    phone_number,
    country: '',
    state: '',
    city: '',
    bank_name: '',
    account_number: 0,
    director_id: '',
    director_bvn: ''
  }
  const [form, setForm] = useForm(initialState)

  useEffect(() => {
    if (data) {
      if (data?.success) {
        flashToast(data?.success, data?.message)
        UserObject.getUser().refresh()
        handleState((v) => !v)
      }
      if (!data?.success) {
        flashToast(data?.success, data?.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSubmit = (e) => {
    e.preventDefault()

    const transData = new FormData()
    transData.append('business_name', form.business_name)
    transData.append('registration_number', form.registration_number)
    transData.append('category', form.category)
    transData.append('email', form.email)
    transData.append('address', form.address)
    transData.append('phone_number', form.phone_number)
    transData.append('country', form.country)
    transData.append('state', form.state)
    transData.append('city', form.city)
    transData.append('bank_name', form.bank_name)
    transData.append('account_number', form.account_number)
    transData.append('director_id', directorValidId.file[0])
    transData.append('cac_form', cacForm.file[0])
    transData.append('cac', cac.file[0])
    transData.append('utility', utilityBill.file[0])
    transData.append('director_bvn', form.director_bvn)
    submit(transData, {
      action: 'dashboard',
      method: 'post',
      encType: 'multipart/form-data'
    })
  }

  return (
    <div
      data-hidden={isHidden}
      className='fixed top-0 left-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-20 data-[hidden=true]:hidden'
    >
      <div className='relative m-10 w-full max-w-4xl rounded-[16px] bg-white p-10'>
        <button
          type='button'
          onClick={() => {
            handleState((v) => !v)
          }}
          className='absolute -top-3 -left-3 rounded-full bg-white p-1 text-gray-800'
        >
          <BsX className='h-5 w-5' />
        </button>
        <img alt='logo' src={Logo} className='h-[46px] w-[140px]' />
        <p className='my-5'>
          Welcome to FedhaGap{' '}
          <span className='font-semibold'>
            {UserObject.getUser().fullname()}
          </span>
          ! Join thousands of business owners with the chance to scale through
          in their business ventures while solving their cash trap problems with
          our agile solution.
        </p>
        <h2 className='my-5'>Enter your KYC Details</h2>
        <div className='flex w-full items-center justify-center '>
          <div className='flex max-w-sm items-center justify-center space-x-3 px-6 py-6 '>
            <button
              type='button'
              onClick={() => {
                setState('bus')
              }}
              className='flex items-center space-x-3'
            >
              <div
                data-active={state === 'invoice_details'}
                className='flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 text-white data-[active=true]:bg-purple-700'
              >
                1
              </div>
              <p className='whitespace-nowrap'>Business Details</p>
            </button>
            <hr className='w-full' />
            <button
              type='button'
              onClick={() => {
                setState('kyc')
              }}
              className='flex items-center space-x-3'
            >
              <div
                data-active={state === 'kyc'}
                className='flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 text-white data-[active=true]:bg-purple-700'
              >
                2
              </div>
              <p className='whitespace-nowrap'>KYC Documents</p>
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          method='post'
          encType='multipart/form-data'
        >
          <div className='w-full'>
            <BusinessDetails
              data={data}
              setForm={setForm}
              isHidden={state === 'bus'}
            />
            <KYCDocuments
              data={data}
              cac={cac}
              utilityBill={utilityBill}
              isHidden={state === 'kyc'}
              directorValidId={directorValidId}
              cacForm={cacForm}
              setForm={setForm}
            />
          </div>
          <div className='col-span-full flex w-full items-center justify-end space-x-3'>
            <div
              data-hidden={!(state === 'bus')}
              className='inline-flex space-x-3 data-[hidden=true]:hidden'
            >
              <button
                type='reset'
                onClick={() => {
                  handleState((v) => !v)
                }}
                className='rounded-md border border-purple-700 px-10 py-5 text-sm font-bold text-purple-700 '
              >
                Cancel
              </button>
              <Submit
                type='button'
                onClick={(e) => {
                  setState('kyc')
                }}
              >
                Continue
              </Submit>
            </div>
            <div
              data-hidden={!(state === 'kyc')}
              className='inline-flex space-x-3 data-[hidden=true]:hidden'
            >
              <button
                type='button'
                onClick={() => {
                  setState('bus')
                }}
                className='stracking-white rounded-md border border-purple-700 px-10 py-5 text-sm font-bold text-purple-700 '
              >
                Previous
              </button>
              <Submit isLoading={loading !== 'idle'}>Submit</Submit>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const BusinessDetails = ({ data, setForm, isHidden }) => {
  const { email, phone_number, organization } = UserObject.getUser()
  const [states, setStates] = useState([])

  return (
    <div
      className='grid w-full grid-cols-1 gap-5 data-[hidden=true]:hidden sm:grid-cols-2 md:gap-10'
      data-hidden={!isHidden}
    >
      <Input
        onChange={setForm}
        label='Business Name'
        name='business_name'
        normalize
        placeholder='Business Name'
        defaultValue={organization?.name}
        error={data?.errors?.business_name}
      />
      <Input
        onChange={setForm}
        label='Registration Number'
        name='registration_number'
        normalize
        placeholder='Registration Number'
        error={data?.errors?.registration_number}
      />
      <Categories
        onChange={setForm}
        label='Category / Products'
        name='category'
        placeholder='Search for Business Category'
        error={data?.errors?.category}
      />
      <Input
        onChange={setForm}
        label='Email Address'
        name='email'
        type='email'
        defaultValue={email}
        normalize
        placeholder='email'
        error={data?.errors?.email}
      />
      <div className='col-span-full'>
        <Input
          onChange={setForm}
          label='Address'
          name='address'
          normalize
          placeholder='Address'
          error={data?.error?.address}
        />
      </div>
      <Input
        onChange={setForm}
        label='Phone Number'
        name='phone_number'
        defaultValue={phone_number}
        normalize
        placeholder='Phone number'
        error={data?.errors?.phone_number}
      />

      <Countries
        setStates={setStates}
        onChange={setForm}
        label='Country'
        name='country'
        placeholder='Country'
        error={data?.errors?.country}
      />
      <States
        states={states}
        onChange={setForm}
        label='State'
        name='state'
        placeholder='State'
        error={data?.errors?.state}
      />
      <Input
        onChange={setForm}
        label='City'
        name='city'
        normalize
        placeholder='City'
        error={data?.error?.city}
      />
      <Input
        onChange={setForm}
        label='Account Number'
        name='account_number'
        type='number'
        placeholder='Enter account number'
        normalize
        error={data?.errors?.account_number}
      />
      <Banks
        top
        onChange={setForm}
        label='Bank Name'
        placeholder='Bank Name'
        name='bank_name'
        error={data?.errors?.bank_name}
      />
    </div>
  )
}

const KYCDocuments = ({
  utilityBill,
  directorValidId,
  cacForm,
  cac,
  setForm,
  data,
  isHidden
}) => {
  return (
    <div
      className='grid w-full grid-cols-1 gap-5 data-[hidden=true]:hidden sm:grid-cols-2 md:gap-10'
      data-hidden={!isHidden}
    >
      <FileInput
        label="Director's Valid Id"
        name='director_id'
        def={directorValidId}
      />
      <FileInput
        label='Valid 3 Months Utility Bill'
        def={utilityBill}
        name='utility'
      />
      <FileInput label='CAC Certificate' name='cac' def={cac} />
      <Input
        onChange={setForm}
        label='BANK VERIFICATION NUMBER'
        name='director_bvn'
        error={data?.errors?.director_bvn}
        normalize
        placeholder='Bank Verification Number'
      />
      <FileInput
        label='Certified True Copy of the Form CAC/BN1'
        def={cacForm}
        name='cac_form'
      />
    </div>
  )
}

const FileInput = ({
  label,
  placeholder,
  note,
  def,
  multiple = false,
  ...props
}) => {
  const [files, setFiles] = useState([])

  useEffect(() => {
    def.setFile(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  return (
    <div className='inline-flex w-full flex-col items-start justify-start'>
      <span className=' w-full text-base text-[18px] font-[700] capitalize text-gray-700'>
        {label}
      </span>
      <label className='form-input text-gray-800s mt-2 w-full border bg-transparent px-4 py-4 text-base font-semibold tracking-wide placeholder:capitalize focus:border-purple-800 focus:outline-none'>
        <input
          type='file'
          className='hidden'
          onChange={(e) => {
            setFiles((v) =>
              multiple ? [...v, ...e.target.files] : [...e.target.files]
            )
          }}
          {...props}
        />
        <div className='flex items-center justify-between'>
          <p className='text-gray-400'>{placeholder}</p>
          <MdOutlineDriveFolderUpload className='h-6 w-6' />
        </div>
      </label>
      <p className='mt-[7.8px] text-[14px] font-[700] leading-[21px] text-[#A0ABBB]'>
        {note}
      </p>
      <div className='mt-2 flex w-full flex-col items-start justify-start space-y-2'>
        {files.map((v, i) => (
          <div
            key={i}
            className='flex w-full flex-col items-start justify-start'
          >
            <div className='flex w-full items-center justify-between'>
              <p>{v.name}</p>
              <button className='focus:outline-none'>
                <BsX className='h-4 w-4' />
              </button>
            </div>
            <div className='h-2 w-full rounded-full bg-green-600' />
          </div>
        ))}
      </div>
    </div>
  )
}
export default DashboardIndex
