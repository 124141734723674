import Input from '../../Components/Forms/Input'
import Password from '../../Components/Forms/Password'
import Submit from '../../Components/Forms/Submit'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import HeaderLogo from '../../Components/LandingPage/HeaderLogo'
import { useContext, useEffect } from 'react'
import useTitle from '../../Hooks/useTitle'
import ToastContext from '../../Routes/ToastContext'

const Complete = () => {
  useTitle('Complete | FedhaGap SMEs')

  const { data, Form, formAction, state } = useFetcher()
  const navigate = useNavigate()
  const { flashToast } = useContext(ToastContext)

  const { id } = useParams()

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      navigate({
        pathname: '/admin/login'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className='h-full w-full pb-20'>
      <HeaderLogo />

      {/* <div className="my-10">
                <p className="text-gray-800" style={{ fontSize: '18px' }}>Already have an account <Link to="/admin/login"
                    className="text-purple-900 font-semibold">Login</Link>
                </p>
            </div> */}
      <h1 className='mb-8 mt-14' style={{ fontSize: '44px', fontWeight: 800 }}>
        Get Started
      </h1>
      <Form method='post' action={formAction}>
        <Input
          label='First Name'
          name='first_name'
          normalize
          placeholder='Enter your first name'
          error={data?.errors?.first_name}
        />
        <Input
          label='Last Name'
          name='last_name'
          normalize
          placeholder='Enter your last name'
          error={data?.errors?.last_name}
        />
        <Input
          label='Phone Number'
          name='phone_number'
          placeholder='Enter your Phone Number'
          error={data?.errors?.phone_number}
        />
        <Password
          label='password'
          name='password'
          placeholder='Enter new Password'
          error={data?.errors?.password}
        />
        <input type='hidden' name='profile_id' defaultValue={id} />
        <Submit isLoading={state !== 'idle'}>Get Started</Submit>
      </Form>
    </div>
  )
}

export default Complete
