const Select = ({ children, ...props }) => {
  return (
    <select
      className='outline-none border border-gray-400/50 w-full rounded-md py-3'
      {...props}
    >
      {children}
    </select>
  )
}

export default Select
