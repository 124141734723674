class DataObject {
  constructor (response = {}) {
  }

  static from (data) {
    return new this(data)
  }
}

export default DataObject
