import { Outlet, useNavigate } from "react-router-dom";
import useToggler from "../Hooks/useToggler";
import NavBar from "../Components/Dashboard/NavBar";
import TopBar from "../Components/Dashboard/TopBar";
import ProtectedRoute from "../Routes/ProtectedRoute";
import { useEffect } from "react";
import useIdle from "../Hooks/useIdle";
import UserObject from "../DataObjects/UserObject";
import Toast from "../Components/Dashboard/Notification/Toast";

const Dashboard = () => {
  const [value, click] = useToggler(false);
  const isIdle = useIdle({ timeToIdle: 1.8e6, inactivityEvents: [] });
  const navigate = useNavigate();

  useEffect(() => {
    if (isIdle) {
      UserObject.logOutUser();
      navigate("/admin/login");
    }
  }, [isIdle, navigate]);

  return (
    <ProtectedRoute>
      <Toast />
      <div className="relative flex h-screen w-full items-start justify-start bg-[#F7F8F9] font-montserrat">
        <Loader />
        <NavBar value={value} click={click} />
        <div className="flex h-screen w-full flex-1 flex-col items-start justify-start">
          <TopBar value={value} click={click} />
          <div className="h-full w-full overflow-y-auto px-8 py-10 md:px-16">
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

const Loader = () => {
  useEffect(() => {
    const handle = (e) => {
      // console.log("start", e);
    };

    window.addEventListener("start-request", handle);

    return () => {
      window.addEventListener("start-request", handle);
    };
  });

  return (
    <div className="fixed top-0 left-0 z-[9999999] hidden h-screen w-full items-center justify-center bg-purple-900/10">
      <span className="inline-flex aspect-square h-24 animate-perspective bg-indigo-500"></span>
    </div>
  );
};

export default Dashboard;
