import { Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { BsSearch, BsThreeDots, BsX } from 'react-icons/bs'
import { Await, useFetcher, useLoaderData, useSubmit } from 'react-router-dom'
import Panel from '../../../Components/Dashboard/Panel'
import useTitle from '../../../Hooks/useTitle'
import InvoiceEmpty from '../../../Assets/images/InvoiceEmpty.png'
import useTable from '../../../Components/Dashboard/Table/useTable'
import PaginateAll from '../../../Components/Utils/PaginateAll'
import Search from '../../../Components/Dashboard/Search'
import Portal from '../../../Components/Dashboard/Portal'
import ToastContext from '../../../Routes/ToastContext'
import Submit from '../../../Components/Forms/Submit'
import Input from '../../../Components/Forms/Input'
import { Dropdown, Popconfirm } from 'antd'
import UserObject from '../../../DataObjects/UserObject'
import PageHeader from '../../../Components/Dashboard/PageHeader'
import UserRolesService from '../../../Services/Dashboard/UserRolesService'
import Select from '../../../Components/Forms/Select'
import RolesService from '../../../Services/Dashboard/RolesService'
import MemberService from '../../../Services/Dashboard/MemberService'
import ProfileIcon from './../../../Assets/images/ProfileIcon.png'

const Members = () => {
  useTitle('Members')
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable()
  const items = useLoaderData()
  const [viewId, setViewId] = useState(null)
  const [editId, setEditId] = useState(null)
  const [permissionId, setPermissionId] = useState(null)
  const submit = useSubmit()
  const { flashToast } = useContext(ToastContext)

  const menuitems = (v) => {
    const menu = []

    if (v?.active_status !== '0') {
      menu.push({
        key: '2',
        label: (
          <button
            onClick={() => setViewId(v)}
            className='text-sm text-blue-500 focus:outline-none'
          >
            View Profile
          </button>
        )
      })

      menu.push({
        key: '3',
        label: (
          <button
            className='text-sm text-blue-500 focus:outline-none'
            onClick={() => setEditId(v)}
          >
            Edit Profile
          </button>
        )
      })

      menu.push({
        key: '4',
        label: (
          <button
            className='whitespace-nowrap text-sm text-blue-500 focus:outline-none'
            onClick={() => setPermissionId(v)}
          >
            Manage Permissions
          </button>
        )
      })

      menu.push({
        key: '5',
        label: (
          <Popconfirm
            title={`${v?.active_status === '1' ? 'Deactivate' : 'Activate'} ${
              v.first_name
            }`}
            onConfirm={() => {
              submit(
                {
                  profile_id: v.profile_id,
                  status: v?.active_status === '1' ? 'deactivate' : 'activate'
                },
                {
                  method: 'delete'
                }
              )
            }}
            okText={<span className='text-black'>Yes</span>}
            className='text-black'
          >
            {v?.active_status === '1' ? 'Deactivate user' : 'Activate User'}
          </Popconfirm>
        )
      })
    }

    if (v?.active_status === '0') {
      menu.push({
        key: '1',
        label: (
          <button
            onClick={() =>
              MemberService.email({
                profile_id: v?.profile_id,
                onboard_url:
                  window.location.hostname + '/admin/complete/onboard'
              }).then((r) => flashToast(r?.success ?? false, r?.message))}
            className='text-sm text-blue-500 focus:outline-none'
          >
            Resend Email
          </button>
        )
      })

      menu.push({
        key: '5',
        label: (
          <Popconfirm
            title={`Delete ${v?.first_name} ${v?.last_name}`}
            onConfirm={() => {
              submit(
                {
                  id: v.profile_id
                },
                {
                  method: 'patch'
                }
              )
            }}
            okText={<span className='text-black'>Yes</span>}
            className='text-black'
          >
            Delete
          </Popconfirm>
        )
      })
    }

    return menu
  }

  return (
    <div className='w-full'>
      <PageHeader>User Management</PageHeader>
      <div
        id='portal'
        className='my-4 flex w-full items-center justify-end'
      />
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Name</ColumnHeader>
            <ColumnHeader>Phone Number</ColumnHeader>
            <ColumnHeader>Role</ColumnHeader>
            <ColumnHeader>Status</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>
                    <span className='whitespace-nowrap'>
                      {v?.first_name} {v?.last_name}
                    </span>
                    <br />
                    <span>{v.email}</span>
                  </Column>
                  <Column>{v.phone_number}</Column>
                  <Column>
                    <span className='capitalize'>{v?.user_role?.roleName}</span>
                  </Column>
                  <Column>
                    <Status status={v?.active_status} />
                  </Column>
                  <Column>
                    {!UserObject.getUser().isAdmin() && (
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: menuitems(v)
                        }}
                        placement='bottomRight'
                        arrow={{ pointAtCenter: true }}
                      >
                        <div className='flex items-center justify-center'>
                          <BsThreeDots className='h-4 w-4' />
                        </div>
                      </Dropdown>
                    )}
                  </Column>
                </Row>
              ))}
          </Body>
        </Table>
        <MembersEmptyState items={items} />
        <ViewMemberButton view={viewId} />
        <EditMemberButton view={editId} />
        <Permission view={permissionId} />
      </Panel>
      <PaginateAll items={items} />
    </div>
  )
}
const Status = ({ status }) => {
  const colors = [
    {
      color: 'text-yellow-700',
      key: 'Pending',
      background: 'bg-yellow-200/50'
    },
    { color: 'text-green-700', key: 'Active', background: 'bg-green-200/50' },
    { color: 'text-red-700', key: 'Disabled', background: 'bg-red-200/50' }
  ]

  return (
    <span
      className={`${colors[status]?.color} capitalize ${colors[status]?.background} rounded-full px-3 py-1 text-xs `}
    >
      {colors[status]?.key}
    </span>
  )
}

const MembersEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div />}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className='flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]'>
                <img src={InvoiceEmpty} alt='Transactions' />
                <p className='mt-5 text-[#64748B]'>
                  You don’t have any transaction <br /> at the time
                </p>
                <CreateMemberButton />
              </div>
            )
          }

          return (
            <Portal selector='#portal'>
              <div className='inline-flex w-full items-baseline justify-end space-x-2'>
                <Search />
                <CreateMemberButton />
              </div>
            </Portal>
          )
        }}
      </Await>
    </Suspense>
  )
}

const CreateMemberButton = () => {
  const [state, setState] = useState(false)
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className='flex items-center justify-center rounded-[8px] bg-black px-6 py-2 text-white'
      >
        <span>+ Onboard Staff</span>
      </button>
      <div
        data-active={state}
        className=' fixed top-0 left-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden'
      >
        <div className='relative w-full max-w-sm rounded-[16px] bg-white py-8'>
          <button
            onClick={() => setState((v) => !v)}
            className='absolute -left-3 -top-3 rounded-full bg-white'
          >
            <BsX className='h-6 w-6' />
          </button>
          <MemberModal setState={setState} />
        </div>
      </div>
    </>
  )
}

const MemberModal = ({ setState }) => {
  const { Form, data, state } = useFetcher()
  const { flashToast } = useContext(ToastContext)
  const [allRoles, setAllRoles] = useState([])

  useEffect(() => {
    RolesService.roles().then((r) => setAllRoles(r?.data?.data))
  }, [])

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      setState((v) => !v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <h1 className='px-6 text-left text-xl font-semibold'>New User</h1>
      <div className='mt-10'>
        <div className='w-full text-left'>
          <div className='px-6 py-4'>
            <Form method='post'>
              <Input
                normalize
                name='email'
                type='email'
                label='Email'
                error={data?.errors?.email}
              />
              <input
                name='onboard_url'
                type='hidden'
                value={window.location.hostname + '/admin/complete/onboard'}
              />
              <Select name='role_token'>
                <option>--Select--</option>
                {allRoles?.map((v, i) => (
                  <option key={i} value={v?.token}>
                    {v?.roleName}
                  </option>
                ))}
              </Select>
              <div className='mb-5' />
              {/* <Switch label="Status" yes={1} no={2} name="status" /> */}
              <div className='mt-2 flex w-full items-center justify-end space-x-2'>
                <button
                  onClick={() => {
                    setState((v) => !v)
                  }}
                  type='reset'
                  className='min-h-[59px] w-full rounded-md border border-[#590DA4] py-3 px-4 text-base font-bold tracking-wider text-[#590DA4] hover:bg-purple-900 hover:text-white disabled:opacity-50'
                >
                  Cancel
                </button>
                <Submit type='submit' isLoading={state !== 'idle'}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

const ViewMemberButton = ({ view }) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    if (view) {
      setState(true)
    }
  }, [view])

  return (
    <>
      <div
        data-active={state}
        className=' fixed top-0 left-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden'
      >
        <div className='relative w-full max-w-sm rounded-[16px] bg-white py-8'>
          <button
            onClick={() => setState((v) => !v)}
            className='absolute -left-3 -top-3 rounded-full bg-white'
          >
            <BsX className='h-6 w-6' />
          </button>
          <MemberViewModal setState={setState} view={view} />
        </div>
      </div>
    </>
  )
}

const MemberViewModal = ({ setState, view }) => {
  const { data } = useFetcher()
  const { flashToast } = useContext(ToastContext)

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      setState((v) => !v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <h1 className='px-6 text-left text-xl font-semibold'>
        View Staff Profile
      </h1>
      <div className='mt-5'>
        <div className='w-full text-left'>
          <div className='flex w-full flex-col items-center justify-start space-y-4 px-6 py-4'>
            <div className='h-[72px] w-[72px] overflow-clip rounded-full border border-blue-500 bg-gray-500'>
              <img
                className='h-full w-full object-fill object-center'
                src={view?.profile_photo_path ?? ProfileIcon}
                alt='profile'
              />
            </div>
            <div className='w-full text-left'>
              <h3 className='text-[18px] font-[700] leading-[27px] text-[#4B5768]'>
                Name
              </h3>
              <p className='text-[18px] font-[700] leading-[27px] text-[#0D0F11]'>
                {view?.first_name} {view?.last_name}
              </p>
            </div>
            <div className='w-full text-left'>
              <h3 className='text-[18px] font-[700] leading-[27px] text-[#4B5768]'>
                Email
              </h3>
              <p className='text-[18px] font-[700] leading-[27px] text-[#0D0F11]'>
                {view?.email}
              </p>
            </div>
            <div className='w-full text-left'>
              <h3 className='text-[18px] font-[700] leading-[27px] text-[#4B5768]'>
                Phone Number
              </h3>
              <p className='text-[18px] font-[700] leading-[27px] text-[#0D0F11]'>
                {view?.phone_number}
              </p>
            </div>
            <div className='w-full text-left'>
              <Status status={view?.active_status} />
            </div>
            <div className='flex w-full items-center justify-end'>
              <button
                onClick={() => {
                  setState(false)
                }}
                className='rounded border-2 border-gray-400/50 px-6 py-3 text-sm font-semibold text-black'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditMemberButton = ({ view }) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    if (view) {
      setState(true)
    }
  }, [view])

  return (
    <>
      <div
        data-active={state}
        className=' fixed top-0 left-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden'
      >
        <div className='relative w-full max-w-sm rounded-[16px] bg-white py-8'>
          <button
            onClick={() => setState((v) => !v)}
            className='absolute -left-3 -top-3 rounded-full bg-white'
          >
            <BsX className='h-6 w-6' />
          </button>
          <MemberEditModal setState={setState} view={view} />
        </div>
      </div>
    </>
  )
}

const MemberEditModal = ({ setState, view }) => {
  const { Form, data, state } = useFetcher()
  const { flashToast } = useContext(ToastContext)
  const [allRoles, setAllRoles] = useState([])

  const [, setRole] = useState('')

  useEffect(() => {
    setRole(view?.user_role?.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      setState((v) => !v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    RolesService.roles().then((r) => setAllRoles(r?.data?.data))
  }, [])

  return (
    <>
      <h1 className='px-6 text-left text-xl font-semibold'>
        Edit Profile for {view?.first_name} {view?.last_name}
      </h1>
      <div className='mt-5'>
        <div className='w-full text-left'>
          <div className='px-6 py-4'>
            <Form method='put'>
              <input
                type='hidden'
                name='profile_id'
                defaultValue={view?.profile_id}
              />
              <Input
                normalize
                name='first_name'
                label='First Name'
                defaultValue={view?.first_name}
                error={data?.errors?.first_name}
              />
              <Input
                normalize
                name='last_name'
                label='Last Name'
                defaultValue={view?.last_name}
                error={data?.errors?.last_name}
              />
              <Input
                normalize
                name='email'
                label='Email'
                defaultValue={view?.email}
                error={data?.errors?.email}
              />
              <Input
                normalize
                name='phone_number'
                label='Phone Number'
                defaultValue={view?.phone_number}
                error={data?.errors?.phone_number}
              />
              <Select name='role_token'>
                <option>--Select--</option>
                {allRoles?.map((v, i) => (
                  <option
                    key={i}
                    value={v?.token}
                    selected={v?.token === view?.user_role?.token}
                  >
                    {v?.roleName}
                  </option>
                ))}
              </Select>
              <div className='mt-5' />
              <div className='mt-2 flex w-full items-center justify-end space-x-2'>
                <button
                  onClick={() => {
                    setState((v) => !v)
                  }}
                  type='reset'
                  className='min-h-[59px] w-full rounded-md border border-[#590DA4] py-3 px-4 text-base font-bold tracking-wider text-[#590DA4] hover:bg-purple-900 hover:text-white disabled:opacity-50'
                >
                  Cancel
                </button>
                <Submit type='submit' isLoading={state !== 'idle'}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

const Permission = ({ view, setView }) => {
  const [state, setState] = useState(false)
  const { flashToast } = useContext(ToastContext)

  const getPermission = (id) => {
    RolesService.permissionUserById(id).then((r) => {
      const data = r?.data?.addedPermissionData?.map((v) => ({
        key: v.token,
        label: v.permission
      }))
      setAssignedPermission(data)
    })
  }

  useEffect(() => {
    if (view) {
      setState(true)
      getPermission(view?.profile_id)
    }
  }, [view])

  const [permission, setPermission] = useState([])

  const [allPermission, setAllPermission] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [assignedPermission, setAssignedPermission] = useState([])
  const [rAssignedPermission, setRAssignedPermission] = useState([])
  const [rolePermission, setRolePermission] = useState([])

  const getAllPermission = () => {
    RolesService.permissions().then((r) => {
      const data = r?.data?.data.map((v) => ({
        key: v.token,
        label: v.permission
      }))
      setAllPermission(data)
    })
  }

  const getAllUserAssignedPermission = (id) => {
    UserRolesService.permissionById(id).then((r) => {
      const data = r?.data?.rolePermissionData?.map((v) => ({
        key: v.token,
        label: v.permission
      }))
      setRolePermission(data)
    })
  }

  useEffect(() => {
    if (view?.role_token) {
      getAllUserAssignedPermission(view?.role_token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view?.role_token])

  useEffect(() => {
    getAllPermission()
  }, [])

  const handleChecked = (e) => {
    setPermission((p) => {
      if (p.indexOf(e.target.value) === -1) {
        p.push(e.target.value)
      } else {
        p.splice(p.indexOf(e.target.value), 1)
      }
      return p
    })
  }

  const handleAssignedChecked = (e) => {
    setRAssignedPermission((p) => {
      if (p.indexOf(e.target.value) === -1) {
        p.push(e.target.value)
      } else {
        p.splice(p.indexOf(e.target.value), 1)
      }
      return p
    })
  }

  const assignPermission = (id) => {
    setIsLoading(true)
    RolesService.assignPermissionToUser({
      token: id,
      permissions: permission.concat(assignedPermission.map((v) => v.key))
    }).then((r) => {
      flashToast(r.success, r.message)
      setPermission([])
      setIsLoading(false)
      setAllPermission([])
      getAllPermission()
      getPermission(id)
    })
  }

  const removePermission = (id) => {
    setIsLoading(true)
    RolesService.assignPermissionToUser({
      token: id,
      permissions: assignedPermission
        .map((v) => v.key)
        .filter((v) => !rAssignedPermission.includes(v))
    }).then((r) => {
      flashToast(r.success, r.message)
      setRAssignedPermission([])

      setIsLoading(false)
      setAllPermission([])
      setAssignedPermission([])
      getAllPermission()
      getPermission(id)
    })
  }

  return (
    <div
      className={`fixed top-0 left-0 z-50 min-h-screen min-w-full bg-purple-700/70 ${
        state ? 'flex' : 'hidden'
      } h-full items-start justify-center overflow-y-auto pt-20`}
    >
      <div className='relative mx-10 w-full max-w-5xl rounded-[16px] bg-white px-[44.8px] py-[36.96px]'>
        <button
          className='absolute -mt-12 -ml-14 rounded-full'
          onClick={() => {
            setState(false)
            // setView(null);
          }}
        >
          <BsX className='h-8 w-8 bg-white p-1' />
        </button>
        <h3>Manage {view?.first_name}'s permissions</h3>
        <hr className='mt-3 mb-6' />
        <div className='grid grid-cols-1 gap-10 lg:grid-cols-7'>
          <PermissionSection
            label='Unassigned Permissions'
            items={allPermission
              ?.filter(
                (v) => !assignedPermission?.map((i) => i.key).includes(v.key)
              )
              .filter(
                (v) => !rolePermission?.map((i) => i.key).includes(v.key)
              )}
            onChange={handleChecked}
            checked={false}
          />
          <div className='flex h-full w-full flex-col items-center justify-center space-y-10 text-sm'>
            <button
              className='w-full rounded-lg
               bg-[#88C02C] px-4 py-3 text-center font-semibold text-white hover:bg-green-600 disabled:opacity-50'
              onClick={() => {
                assignPermission(view.profile_id)
              }}
              disabled={isLoading}
            >
              Add
            </button>
            <button
              className='w-full rounded-lg bg-[#FD0D1B] px-4 py-3 text-center font-semibold text-white hover:bg-red-600 disabled:opacity-50'
              disabled={isLoading}
              onClick={() => {
                removePermission(view.profile_id)
              }}
            >
              Remove
            </button>
          </div>
          <PermissionSection
            label='Assigned Permissions'
            items={assignedPermission}
            onChange={handleAssignedChecked}
          />
          <PermissionSection
            label='Permissions from Role'
            items={rolePermission}
            checked
            disabled
          />
        </div>
      </div>
    </div>
  )
}

const PermissionSection = ({ children, items, label, ...props }) => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])

  const handleSearch = useCallback(() => {
    setData((s) =>
      items?.filter((v) => {
        if (search === '') {
          return true
        }
        return v?.label?.toLowerCase()?.indexOf(search.toLowerCase()) > -1
      })
    )
  }, [items, search])

  useEffect(() => {
    handleSearch()
  }, [handleSearch])
  return (
    <div className='col-span-2 flex w-full flex-col space-y-3'>
      <h4 className='font-semibold tracking-wide'>{label}</h4>
      <div className='flex w-full items-center justify-start rounded-[8px] border-[1.5px] border-[#b8c0cc] py-[11px] px-4 text-[12px] font-[700] leading-[18px] placeholder:text-[#b8c0cc] focus:outline-none'>
        <BsSearch className='mr-2 aspect-square w-4 text-[#b8c0cc]' />
        <input
          placeholder='Search...'
          className='h-full w-full border-none font-semibold tracking-wide text-gray-800 focus:outline-none'
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </div>
      <div className='flex h-96 w-full flex-col items-start justify-start space-y-2 overflow-y-auto rounded-[8px] bg-[#E7EAEE] p-4 shadow-inner'>
        {data?.map((v, i) => (
          <SelectPermissions {...props} value={v.key} key={i}>
            {v.label}
          </SelectPermissions>
        ))}
      </div>
    </div>
  )
}

const SelectPermissions = ({ children, value, checked, ...props }) => {
  return (
    <label className='inline-flex cursor-pointer items-center justify-start peer-hover:text-purple-700'>
      <input
        type='checkbox'
        className='peer h-3 w-3 appearance-none rounded-sm checked:bg-purple-600 hover:border-purple-500 disabled:opacity-50'
        value={value}
        {...props}
        defaultChecked={checked}
      />
      <span className='ml-2 select-none text-xs font-semibold tracking-tight text-gray-500 peer-checked:text-purple-800'>
        {children}
      </span>
    </label>
  )
}

export default Members
